// These must be the first lines in src/index.js
//sort-imports-ignore
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import smoothscroll from 'smoothscroll-polyfill';

import { loadableReady } from '@loadable/component';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/app-component';

// kick off the polyfill! required for IE 11.
smoothscroll.polyfill();

loadableReady(() => {
  ReactDOM.hydrate(<App />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// EMD-1462: Commented out to avoid unregistering service worker used for netcore
//serviceWorker.unregister();
