import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Icon, Loader, Toast } from 'rs-emd-ui-atoms';
import { PartListDetailArea, PartsListDetailsTitleArea, PartsListsSummary } from '.';
import { PartsListActionsConfirmationModal } from '..';
import { useUser, useUserDispatch } from '../../../../../../../components/app/user-context';
import { BulkAddToBasket_BasketLine, PartsListDetails, ShareProductsType } from '../../../../../../../generated/graphql';
import { headerService } from '../../../../../../../services/header-service';
import { myAccountPartsListsService } from '../../../../../../../services/my-account-parts-lists-service';
import { clickEvent } from '../../../../../../../tagging/ensighten';
import { getLabel } from '../../../../../helpers/html.utils';
import { BulkAddResultModal } from '../../../../basket-purchase/basket';
import { ConfirmationModal, MetaDataAndTagging, ShareProductsModal } from '../../../../shared';
import { Action, PartsListDetailsActionType, PartsListDetailsPageProps, State } from './parts-list-details-page-component.model';
import styles from './parts-list-details-page-component.module.scss';

export const PartsListDetailsPage: FunctionComponent<PartsListDetailsPageProps> = (props: PartsListDetailsPageProps) => {
  const [state, dispatch] = useReducer(reducer, {
    data: props.data,

    isAddingProductsToList: false,
    productsToAddContainErrors: false,
    isGettingListDetails: false,
    isAddToBasketClicked: false,
    isShareModalVisible: false,
    isShareBasketToastVisible: false,
    lineIdsToDelete: [],
    isDeleteProductsToastVisible: false,
    isDeleteListConfirmationVisible: false,

    isActionCompleted: false,

    //flag to show modal for when product is added from product entry
    showProdAddConfirmationModal: false,
  });

  const userDispatch = useUserDispatch();
  const location = useLocation();
  const history = useHistory();
  const user = useUser();

  useEffect(() => {
    if (state.productsAdded && state.productsAdded.length > 0) {
      dispatch({ type: PartsListDetailsActionType.ResetProductsAdded });
    }
  });

  useEffect(() => {
    if (state.isActionCompleted) {
      dispatch({ type: PartsListDetailsActionType.ResetIsActionComplete });
    }
  }, [state.isActionCompleted]);

  return (
    <div className={`inner-page-wrapper ${styles['parts-list-details-container']}`}>
      {/* Head data */}
      <MetaDataAndTagging tagging={props.data.tagging} metaData={props.data.metaData} />

      <PartsListsSummary labels={props.data.labels} partsLists={state.data.partsLists.partsLists} updateListCallback={getListDetails} />

      {state.isGettingListDetails ? (
        <div className={styles['loader-container']}>
          <Loader />
        </div>
      ) : (
        <div className={styles['parts-list-details']}>
          <PartsListDetailsTitleArea
            labels={props.data.labels}
            partsListDetails={state.data.details as PartsListDetails}
            isBulkAddComplete={state.bulkAddToBasketRes === undefined && !state.productsToAddContainErrors}
            addListToBasketCallback={() => addListToBasket(true)}
            shareListCallback={(partsListId) =>
              dispatch({
                type: PartsListDetailsActionType.ToggleShareListModal,
                isShareBasketToastVisible: false,
                isActionCompleted: false,
                partsListId: partsListId,
              })
            }
            isActionCompleted={state.isActionCompleted}
            isAddingProductsToListCallback={() => dispatch({ type: PartsListDetailsActionType.SetIsAddingProductsToList, isAdding: true })}
            isUpdatingQuantity={state.isUpdatingQuantity}
            addProductsToListCompleteCallback={addProductsToList}
            deleteListCallback={() => dispatch({ type: PartsListDetailsActionType.ShowDeleteListConfirmation })}
          />
          {/** Products Area */}
          {state.data.details?.lines && (
            <PartListDetailArea
              labels={props.data.labels}
              listId={props.data.details?.id ?? 0}
              lines={state.data.details?.lines}
              isUpdatingList={state.isAddingProductsToList}
              isUpdatingQuantity={state.isUpdatingQuantity}
              productsAddedToBasket={state.productsAdded}
              isBulkAddComplete={state.bulkAddToBasketRes === undefined && !state.productsToAddContainErrors}
              isDeleteComplete={!state.isDeleteListConfirmationVisible && state.lineIdsToDelete.length === 0}
              onRemovedFromPartList={(lineIds, isRemoveFromProductEntry) => {
                if (isRemoveFromProductEntry) {
                  deletePartsListLines(lineIds);
                } else {
                  dispatch({ type: PartsListDetailsActionType.SetLineIdsToDelete, ids: lineIds });
                }
              }}
              onAddToBasketCallback={(items, addedFromProductEntry) => {
                addedFromProductEntry ? addedProductToBasket(items[0]) : addListToBasket(true, items);
              }}
              onUpdatingQuantityCallback={(updatingQuantity) =>
                dispatch({ type: PartsListDetailsActionType.SetUpdatingQuantity, updatingQuantity })
              }
            />
          )}

          <div className={styles['footer-btns']}>
            {/** Back button */}
            <Link to='/user/myaccount/partslists' className={styles['back-btn']}>
              <Icon name='chev-left' className='link' />
              <span className='link'>{getLabel(props.data.labels, 'back_to_parts_list')}</span>
            </Link>

            <Button
              id='parts-list-detail-add-list-to-basket'
              className={styles['add-list-to-basket-btn']}
              text={getLabel(props.data.labels, 'add_list_to_basket')}
              icon={state.isAddToBasketClicked ? 'circular-loader' : undefined}
              disabled={state.isAddToBasketClicked || state.data.details?.lines.length === 0 || state.isUpdatingQuantity}
              onClick={() => {
                dispatch({ type: PartsListDetailsActionType.AddToBasketClicked });
                clickEvent({ _Info: 'Add list to basket button', __Result: props.data.details?.id });
                addListToBasket(true);
              }}
            />
          </div>
        </div>
      )}

      {state.isShareModalVisible && (
        <ShareProductsModal
          labels={props.data.labels}
          isCustomerLoggedIn={user.customer.isLoggedIn}
          productQuantities={
            props.data.details?.lines.map((line) => {
              return { stockCode: line.product.title.stockCode, quantity: line.quantity };
            }) ?? []
          }
          shareType={ShareProductsType.PartsList}
          shareClickEventInfo='Share list button from details page'
          onCloseCallback={(isBasketShared) => {
            dispatch({
              type: PartsListDetailsActionType.ToggleShareListModal,
              isShareBasketToastVisible: isBasketShared,
              isActionCompleted: true,
            });
          }}
        />
      )}

      {state.bulkAddToBasketRes && !state.showProdAddConfirmationModal && (
        <BulkAddResultModal
          labels={props.data.labels}
          bulkAddToBasketResult={state.bulkAddToBasketRes}
          backToBtnText='back_to_parts_list'
          closeCallback={() => dispatch({ type: PartsListDetailsActionType.AddToBasketCompleted })}
          viewBasketCallback={() => {
            if (location.pathname !== '/basket') window.location.href = '/basket';
          }}
        />
      )}

      {state.showProdAddConfirmationModal && state.productAdded && (
        <ConfirmationModal
          labels={props.data.labels}
          confirmationModalTitle={getLabel(props.data.labels, 'added_to_basket')}
          title={state.productAdded.titleArea.title}
          details={
            <p className={`snippet ${styles.quantity}`}>
              {getLabel(props.data.labels, 'quantity_added').replace('[quantity]', state.productAdded.quantity.toString())}
            </p>
          }
          imageUrl={state.productAdded.titleArea.primaryImageUrl}
          viewButtonHref={'/basket'}
          viewButtonText={getLabel(props.data.labels, 'view_basket')}
          backButtonText='back_to_parts_list'
          closeCallback={() => dispatch({ type: PartsListDetailsActionType.AddToBasketCompleted })}
        />
      )}

      {(state.lineIdsToDelete.length > 0 || state.isDeleteListConfirmationVisible || state.productsToAddContainErrors) && (
        <PartsListActionsConfirmationModal
          labels={props.data.labels}
          titleLabel={state.productsToAddContainErrors ? 'add_to_basket_confirmation' : 'delete_confirmation'}
          actionBtnLabel={state.productsToAddContainErrors ? 'add_to_basket' : 'delete'}
          content={
            state.productsToAddContainErrors ? (
              <p
                dangerouslySetInnerHTML={{ __html: getLabel(props.data.labels, 'invalid_products_message') }}
                className={styles['invalid-products']}
              />
            ) : (
              getLabel(
                props.data.labels,
                state.isDeleteListConfirmationVisible ? 'delete_confirmation_list_text' : 'delete_confirmation_products_text'
              )
            )
          }
          onCloseCallback={() =>
            state.productsToAddContainErrors
              ? dispatch({ type: PartsListDetailsActionType.ToggleErrorProductsConfirmationModal, isVisible: false })
              : closeDeleteConfirmation()
          }
          onActionBtnClick={() =>
            state.productsToAddContainErrors
              ? addListToBasket(false)
              : state.isDeleteListConfirmationVisible
              ? deleteList()
              : deletePartsListLines()
          }
        />
      )}

      {/** Shared basket toast */}
      <Toast
        showToast={state.isShareBasketToastVisible}
        backgroundColour='success'
        text={getLabel(props.data.labels, 'parts_list_shared_toast')}
        icon='check'
      />

      {/** Products deleted toast */}
      <Toast
        text={getLabel(props.data.labels, 'products_deleted_successfully')}
        backgroundColour='success'
        showToast={state.isDeleteProductsToastVisible}
        onCloseCallback={() => dispatch({ type: PartsListDetailsActionType.HideDeleteToast })}
      />
    </div>
  );

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case PartsListDetailsActionType.ToggleShareListModal:
        return {
          ...state,
          isShareModalVisible: !state.isShareModalVisible,
          isShareBasketToastVisible: action.isShareBasketToastVisible ?? false,
          isActionCompleted: action.isActionCompleted,
        };
      case PartsListDetailsActionType.UpdatePartsListLines:
        return {
          ...state,
          data:
            state.data.details && action.lines ? { ...state.data, details: { ...state.data.details, lines: action.lines } } : state.data,
          isAddingProductsToList: false,
        };
      case PartsListDetailsActionType.DeletePartsListLines:
        return {
          ...state,
          data: state.data.details
            ? {
                ...state.data,
                details: {
                  ...state.data.details,
                  lines: state.data.details?.lines.filter((l) => !action.lineIdsToDelete.includes(l.id)) ?? [],
                },
              }
            : state.data,
          isDeleteProductsToastVisible: action.showToast,
        };
      case PartsListDetailsActionType.HideDeleteToast:
        return {
          ...state,
          isDeleteProductsToastVisible: false,
        };
      case PartsListDetailsActionType.SwitchingPartsList:
        return {
          ...state,
          isGettingListDetails: true,
        };
      case PartsListDetailsActionType.SwitchPartsList:
        return {
          ...state,
          data: action.data,
          isGettingListDetails: false,
        };
      case PartsListDetailsActionType.SetIsAddingProductsToList:
        return {
          ...state,
          isAddingProductsToList: action.isAdding,
        };
      case PartsListDetailsActionType.AddToBasketClicked:
        return {
          ...state,
          isAddToBasketClicked: true,
        };
      case PartsListDetailsActionType.AddToBasketCompleted:
        return {
          ...state,
          bulkAddToBasketRes: undefined,
          productsToAddContainErrors: false,
          isAddToBasketClicked: false,
          showProdAddConfirmationModal: false,
          productAdded: undefined,
        };
      case PartsListDetailsActionType.SetBulkAddToBasketRes:
        return {
          ...state,
          bulkAddToBasketRes: action.res,
          productsToAddContainErrors: false,
          showProdAddConfirmationModal: action.product !== undefined,
          productAdded: action.product,
          productsAdded: action.productsAdded,
          validProductsToAddByBulk: undefined,
        };
      case PartsListDetailsActionType.ProductAddedToList:
        return {
          ...state,
          productAdded: action.product,
          showProdAddConfirmationModal: action.product !== undefined,
        };
      case PartsListDetailsActionType.SetLineIdsToDelete:
        return {
          ...state,
          lineIdsToDelete: action.ids,
        };
      case PartsListDetailsActionType.CloseDeleteConfirmationModal:
        return {
          ...state,
          lineIdsToDelete: [],
          isDeleteListConfirmationVisible: false,
          isActionCompleted: true,
        };
      case PartsListDetailsActionType.ShowDeleteListConfirmation:
        return {
          ...state,
          isDeleteListConfirmationVisible: true,
        };
      case PartsListDetailsActionType.ToggleErrorProductsConfirmationModal:
        return {
          ...state,
          productsToAddContainErrors: action.isVisible,
          isAddToBasketClicked: action.isVisible ? state.isAddToBasketClicked : false,
          validProductsToAddByBulk: action.validProductsToAdd,
        };
      case PartsListDetailsActionType.ResetProductsAdded:
        return {
          ...state,
          productsAdded: undefined,
        };
      case PartsListDetailsActionType.ResetIsActionComplete:
        return {
          ...state,
          isActionCompleted: false,
        };
      case PartsListDetailsActionType.SetUpdatingQuantity:
        return {
          ...state,
          isUpdatingQuantity: action.updatingQuantity,
        };
    }
  }

  function deleteList() {
    dispatch({ type: PartsListDetailsActionType.ShowDeleteListConfirmation });

    clickEvent({ _Info: 'Delete list button from details page', __Result: props.data.details?.id.toString() });

    myAccountPartsListsService.deletePartsList(Number(props.data.details?.id)).then((res) => {
      if (res?.isSuccess) {
        closeDeleteConfirmation();

        //redirect to parts lists page
        history.push({
          pathname: `/user/myaccount/partslists`,
          state: { listDeleted: true },
        });
      }
    });
  }

  function closeDeleteConfirmation() {
    dispatch({ type: PartsListDetailsActionType.CloseDeleteConfirmationModal });
  }

  function deletePartsListLines(lineIds?: number[]) {
    const lineIdsToDelete = lineIds ?? state.lineIdsToDelete;

    props.data.details?.id &&
      myAccountPartsListsService
        .deletePartsListLines({ partsListId: props.data.details?.id, partsListLineIds: lineIdsToDelete })
        .then((res) => {
          if (res?.isSuccess) {
            dispatch({ type: PartsListDetailsActionType.DeletePartsListLines, lineIdsToDelete: lineIdsToDelete, showToast: !lineIds });
            closeDeleteConfirmation();
          }
        });
  }

  function addListToBasket(checkForProductErrors: boolean, items?: BulkAddToBasket_BasketLine[]) {
    const productsToAdd =
      items ??
      state.validProductsToAddByBulk ??
      state.data.details?.lines.map((l) => ({ stockCode: l.product.title.stockCode, quantity: l.quantity })) ??
      [];

    //check if any of the products contain invalid quantities
    if (checkForProductErrors) {
      const stockCodesToAdd = productsToAdd.map((i) => i.stockCode);
      const linesToAdd = props.data.details?.lines.filter((l) => stockCodesToAdd.includes(l.product.title.stockCode)) ?? [];
      const validLines = linesToAdd.filter((l) => !l.product.priceArea.errorMessage);

      if (validLines.length < linesToAdd.length) {
        const validStockCodes = validLines.map((l) => l.product.title.stockCode);
        const validProductstoAdd = productsToAdd.filter((p) => p.stockCode && validStockCodes.includes(p.stockCode));

        dispatch({
          type: PartsListDetailsActionType.ToggleErrorProductsConfirmationModal,
          isVisible: true,
          validProductsToAdd: validProductstoAdd,
        });
        return;
      }
    }

    if (productsToAdd.length > 0) {
      myAccountPartsListsService
        .bulkAddToBasket({
          lines: productsToAdd ?? [],
          requestId: state.data.config.topicIdentifier,
        })
        .then((res) => {
          let product = undefined;

          //get product details if only one product was added and it was added successfully (for confirmation modal)
          if (res.isSuccess && productsToAdd?.length === 1) {
            const productDetails = props.data.details?.lines.find((l) => l.product.title.stockCode === productsToAdd[0].stockCode)?.product;

            if (productDetails) {
              product = {
                titleArea: productDetails?.title,
                quantity: productsToAdd[0].quantity ?? 0,
              };
            }
          }

          dispatch({
            type: PartsListDetailsActionType.SetBulkAddToBasketRes,
            res: res,
            productsAdded: productsToAdd.map((p) => p.stockCode as string),
            product: product,
          });

          //update basket total in header
          headerService.getBasketTotal(userDispatch);
        });
    } else {
      dispatch({ type: PartsListDetailsActionType.AddToBasketCompleted });
    }
  }

  function addedProductToBasket(item: { stockCode: string; quantity: number }) {
    const product = props.data.details?.lines.find((l) => l.product.title.stockCode === item.stockCode);

    dispatch({
      type: PartsListDetailsActionType.ProductAddedToList,
      product: product
        ? {
            titleArea: product.product.title,
            quantity: item.quantity,
          }
        : undefined,
    });
  }

  function addProductsToList(isSuccess: boolean) {
    if (!isSuccess) {
      dispatch({ type: PartsListDetailsActionType.SetIsAddingProductsToList, isAdding: false });
      return;
    }

    if (state.data.details) {
      myAccountPartsListsService
        .getPartsListProducts(state.data.details.id)
        .then((res) => dispatch({ type: PartsListDetailsActionType.UpdatePartsListLines, lines: res }));
    }
  }

  //switch to new list's detail page
  function getListDetails(listId: number) {
    dispatch({ type: PartsListDetailsActionType.SwitchingPartsList });

    myAccountPartsListsService.getPartsListDetailsPage(listId).then((res) => {
      if (res.isSuccess) {
        dispatch({ type: PartsListDetailsActionType.SwitchPartsList, data: res });

        //update url without reloading page
        history.push({
          pathname: `/user/myaccount/partslist/${listId}`,
          state: { useCache: true },
        });
      }
    });
  }
};
