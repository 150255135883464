import {
  BulkAddToBasket_BasketLine,
  BulkAddToBasketResponse,
  MyAccountPartsListDetailsPage,
  PartsListLineItem,
  PartsListProductTitle,
} from '../../../../../../../generated/graphql';

export type PartsListDetailsPageProps = {
  data: MyAccountPartsListDetailsPage;
};

export type State = {
  data: MyAccountPartsListDetailsPage;

  isAddingProductsToList: boolean;
  productsToAddContainErrors: boolean;
  bulkAddToBasketRes?: BulkAddToBasketResponse;
  isGettingListDetails: boolean;
  isAddToBasketClicked: boolean;
  isShareModalVisible: boolean;
  isShareBasketToastVisible: boolean;
  lineIdsToDelete: number[];
  isDeleteProductsToastVisible: boolean;
  isDeleteListConfirmationVisible: boolean;
  isUpdatingQuantity?: boolean;

  isActionCompleted: boolean;

  //flag to show modal for when product is added from product entry
  showProdAddConfirmationModal: boolean;
  productAdded?: { titleArea: PartsListProductTitle; quantity: number };
  productsAdded?: string[];

  validProductsToAddByBulk?: BulkAddToBasket_BasketLine[];
};

export enum PartsListDetailsActionType {
  ToggleShareListModal = 'ToggleShareListModal',
  UpdatePartsListLines = 'UpdatePartsListLines',
  DeletePartsListLines = 'DeletePartsListLines',
  HideDeleteToast = 'HideDeleteToast',
  SwitchPartsList = 'SwitchPartsList',
  SwitchingPartsList = 'SwitchingPartsList',
  SetIsAddingProductsToList = 'SetIsAddingProductsToList',
  AddToBasketClicked = 'AddToBasketClicked',
  AddToBasketCompleted = 'AddToBasketCompleted',
  SetBulkAddToBasketRes = 'SetBulkAddToBasketRes',
  ProductAddedToList = 'ProductAddedToList',
  SetLineIdsToDelete = 'SetLineIdsToDelete',
  ShowDeleteListConfirmation = 'ShowDeleteListConfirmation',
  CloseDeleteConfirmationModal = 'CloseDeleteConfirmationModal',
  ToggleErrorProductsConfirmationModal = 'ToggleErrorProductsConfirmationModal',
  ResetProductsAdded = 'ResetProductsAdded',
  ResetIsActionComplete = 'ResetIsActionComplete',
  SetUpdatingQuantity = 'SetUpdatingQuantity',
}

export type Action =
  | {
      type: PartsListDetailsActionType.ToggleShareListModal;
      isShareBasketToastVisible: boolean;
      isActionCompleted: boolean;
      partsListId?: number;
    }
  | { type: PartsListDetailsActionType.UpdatePartsListLines; lines?: PartsListLineItem[] }
  | { type: PartsListDetailsActionType.DeletePartsListLines; lineIdsToDelete: number[]; showToast: boolean }
  | { type: PartsListDetailsActionType.HideDeleteToast }
  | { type: PartsListDetailsActionType.SwitchingPartsList }
  | { type: PartsListDetailsActionType.SwitchPartsList; data: MyAccountPartsListDetailsPage }
  | { type: PartsListDetailsActionType.SetIsAddingProductsToList; isAdding: boolean }
  | { type: PartsListDetailsActionType.AddToBasketClicked }
  | { type: PartsListDetailsActionType.AddToBasketCompleted }
  | {
      type: PartsListDetailsActionType.SetBulkAddToBasketRes;
      res: BulkAddToBasketResponse;
      productsAdded: string[];
      product?: {
        titleArea: PartsListProductTitle;
        quantity: number;
      };
    }
  | {
      type: PartsListDetailsActionType.ProductAddedToList;
      product?: {
        titleArea: PartsListProductTitle;
        quantity: number;
      };
    }
  | {
      type: PartsListDetailsActionType.SetLineIdsToDelete;
      ids: number[];
    }
  | { type: PartsListDetailsActionType.ShowDeleteListConfirmation }
  | { type: PartsListDetailsActionType.CloseDeleteConfirmationModal }
  | {
      type: PartsListDetailsActionType.ToggleErrorProductsConfirmationModal;
      isVisible: boolean;
      validProductsToAdd?: BulkAddToBasket_BasketLine[];
    }
  | { type: PartsListDetailsActionType.ResetProductsAdded }
  | { type: PartsListDetailsActionType.ResetIsActionComplete }
  | { type: PartsListDetailsActionType.SetUpdatingQuantity; updatingQuantity: boolean };
