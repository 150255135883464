import loadable from '@loadable/component';

const ControlledPurchasing = loadable(
  () =>
    import(/* webpackChunkName: "my-account" */ './my-account/my-account-overview/controlled-purchasing/controlled-purchasing-component'),
  {
    resolveComponent: (components) => components.ControlledPurchasing,
  }
);

const MyAccountNavbar = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-navbar/my-account-navbar-component'),
  {
    resolveComponent: (components) => components.MyAccountNavbar,
  }
);

const MyAccountOverview = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-overview/my-account-overview-component'),
  {
    resolveComponent: (components) => components.MyAccountOverview,
  }
);

const LastOrders = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-overview/last-orders/last-orders-component'),
  {
    resolveComponent: (components) => components.LastOrders,
  }
);

const PartList = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-overview/part-list/part-list-component'),
  {
    resolveComponent: (components) => components.PartList,
  }
);

const HeaderArea = loadable(() => import(/* webpackChunkName: "my-account" */ './my-account/shared/header-area/header-area-component'), {
  resolveComponent: (components) => components.HeaderArea,
});

const MyAccountDiscoverMore = loadable(
  () =>
    import(
      /* webpackChunkName: "my-account" */ './my-account/my-account-overview/my-account-discover-more/my-account-discover-more-component'
    ),
  {
    resolveComponent: (components) => components.MyAccountDiscoverMore,
  }
);

const ForgotPasswordPage = loadable(() => import(/* webpackChunkName: "my-account" */ './forgot-password/forgot-password-component'), {
  resolveComponent: (components) => components.ForgotPasswordPage,
});

const LoginPage = loadable(() => import(/* webpackChunkName: "login" */ './login/login-page/login-page-component'), {
  resolveComponent: (components) => components.LoginPage,
});

const LoginToast = loadable(() => import(/* webpackChunkName: "login" */ './login/login-toast/login-toast-component'), {
  resolveComponent: (components) => components.LoginToast,
});

const ProfileField = loadable(() => import(/* webpackChunkName: "my-account" */ './profile-field/profile-field-component'), {
  resolveComponent: (components) => components.ProfileField,
});

const ProfilePage = loadable(() => import(/* webpackChunkName: "my-account" */ './my-account/profile-page/profile-page-component'), {
  resolveComponent: (components) => components.ProfilePage,
});

const RegistrationPage = loadable(() => import(/* webpackChunkName: "my-account" */ './registration-page/registration-page-component'), {
  resolveComponent: (components) => components.RegistrationPage,
});

const ResetPasswordPage = loadable(() => import(/* webpackChunkName: "my-account" */ './reset-password/reset-password-component'), {
  resolveComponent: (components) => components.ResetPasswordPage,
});

const SubscriptionPage = loadable(() => import(/* webpackChunkName: "subscription" */ './subscription/subscription-page-component'), {
  resolveComponent: (components) => components.SubscriptionPage,
});

const UnsubscriptionPage = loadable(() => import(/* webpackChunkName: "subscription" */ './unsubscription/unsubscription-page-component'), {
  resolveComponent: (components) => components.UnsubscriptionPage,
});

const ItemCountDisplay = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/shared/item-count-display/item-count-display-component'),
  {
    resolveComponent: (components) => components.ItemCountDisplay,
  }
);

const ProductSummaryParcelItem = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/shared/product-summary-item/product-summary-parcel-item-component'),
  {
    resolveComponent: (components) => components.ProductSummaryParcelItem,
  }
);

const ProductListProduct = loadable(
  () =>
    import(
      /* webpackChunkName: "my-account-orders" */ './my-account/shared/product-list/product-list-product/product-list-product-component'
    ),
  {
    resolveComponent: (components) => components.ProductListProduct,
  }
);

const ProductListProductDisplay = loadable(
  () =>
    import(
      /* webpackChunkName: "my-account-orders" */ './my-account/shared/product-list/product-list-product-display/product-list-product-display-component'
    ),
  {
    resolveComponent: (components) => components.ProductListProductDisplay,
  }
);

const RegistrationTokenExpiredPage = loadable(
  () => import(/* webpackChunkName: "registration-token-expired" */ './token-expired/token-expired-page-component'),
  {
    resolveComponent: (components) => components.RegistrationTokenExpiredPage,
  }
);

export {
  ControlledPurchasing,
  ForgotPasswordPage,
  HeaderArea,
  ItemCountDisplay,
  LastOrders,
  LoginPage,
  LoginToast,
  MyAccountDiscoverMore,
  MyAccountNavbar,
  MyAccountOverview,
  PartList,
  ProductListProduct,
  ProductListProductDisplay,
  ProductSummaryParcelItem,
  ProfileField,
  ProfilePage,
  RegistrationPage,
  RegistrationTokenExpiredPage,
  ResetPasswordPage,
  SubscriptionPage,
  UnsubscriptionPage,
};
